<template>
  <div>
    <component ref="model" :is="getComonent(config.modelName||'Model-1')" backdrop="static" @ready="modelReady" @Hide="onhide">
      <div style="position:relative">
        <h6>
          基础设置
        </h6>
        <div v-if="this.form.items && this.form.items.length > 0" class="" style="position:absolute;top:0;right:0;padding:0 5px;cursor:pointer;" @click="$refs.model.hideModal()">
          <i class="fa fa-times"></i>
        </div>
        <!--<div class="form-group has-feedback">
          <label for="Title">标题</label>
          <input type="text" class="form-control" id="Title" v-model.trim="form.title" placeholder="请输入标题">
        </div>

        <div class="form-group">
          <label for="Text">简介</label>-->
        <!--<textarea class="form-control" id="Text" v-model.trim="form.text" placeholder="请输入简介"></textarea>-->
        <!--<PellEditor v-model.trim="form.text" />
        </div>-->
        <!--<div class="form-group">
          <button v-if="!form.introImage" type="button" class="btn btn-outline-primary" @click="showBaseFileLibDialog()">{{form.introImage?`${form.introImage.name}`:'选择素材'}}</button>
          <div v-if="form.introImage&&form.introImage.isImage">
            <div class="card-tools float-right">
              <button type="button" class="btn btn-box-tool" @click="form.introImage=null" title="删除"><i class="fas fa-trash"></i></button>
            </div>
            <a>
              <div style="height:100px;background-repeat:no-repeat;background-size:contain;background-color:#ccc;background-position:center" :style="{'background-image':'url(' + form.introImage.url + ')'}"></div>
            </a>
          </div>
          <div v-else-if="form.introImage&&form.introImage.isVideo">
            {{form.introImage.name}}
            <div class="card-tools float-right">
              <button type="button" class="btn btn-box-tool" @click="form.introImage=null" title="删除"><i class="fas fa-trash"></i></button>
            </div>
            <video style="width:100%;" :src="form.introImage.url" :poster="((form.introImage.file||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')" controls />
          </div>
          <div v-else-if="form.introImage&&form.introImage.isAudio">
            <button type="button" class="btn btn-outline-primary" @click="showBaseFileLibDialog()">{{form.introImage?`${form.introImage.name}`:'选择素材'}}</button>
          </div>
        </div>-->

        <div class="form-group">
          <button v-if="form.audio" type="button" class="btn btn-outline-primary mb-1" @click="showAudioLibDialog()">音乐:{{(form.audio||{}).name||null}}</button>
          <button v-else type="button" class="btn btn-outline-primary mb-1" @click="showAudioLibDialog()">选择背景音乐</button>
          <audio v-if="form.audio" class="form-control" :src="(form.audio||{}).url||null" controls></audio>
        </div>
        <template v-if="form.audio">
          <div class="form-group custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="customSwitchAudioAutoPlay" v-model.trim="form.audioAutoPlay">
            <label class="custom-control-label" for="customSwitchAudioAutoPlay">自动播放</label>
          </div>
          <div class="form-group custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="customSwitchAudioLoop" v-model.trim="form.audioLoop">
            <label class="custom-control-label" for="customSwitchAudioLoop">循环播放</label>
          </div>
        </template>
        <!--<div class="form-group custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="customSwitchFeatured" v-model.trim="form.featured">
          <label class="custom-control-label" for="customSwitchFeatured">精选</label>
        </div>-->
        <!--<div class="form-group">
          <button class="btn btn-outline-primary"> 切换皮肤：{{form.skin||'默认皮肤'}} </button>
        </div>-->
        <div class="form-group">
          <label>转场效果</label>
          <select class="form-control" v-model="form.blend">
            <option v-for="i in blendModes" :value="i.blend">{{i.name}}</option>
          </select>
        </div>
        <div class="form-group custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="customSwitchLittlePlanetIntro" v-model.trim="form.littlePlanetIntro">
          <label class="custom-control-label" for="customSwitchLittlePlanetIntro">小行星进入</label>
        </div>
        <!--<div class="form-group custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="customSwitchHideCount" v-model.trim="form.hideCount">
          <label class="custom-control-label" for="customSwitchHideCount">隐藏计数器<span v-if="!form.isPro" style="color:orange" @click.prevent="$refs.proDescription.show=true">（Pro 功能<i class="fas fa-question-circle"></i>）</span></label>
        </div>
        <div class="form-group custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="customSwitchHideLogo" v-model.trim="form.hideLogo">
          <label class="custom-control-label" for="customSwitchHideLogo">隐藏 Logo<span v-if="!form.isPro" style="color:orange" @click.prevent="$refs.proDescription.show=true">（Pro 功能<i class="fas fa-question-circle"></i>）</span></label>
        </div>
        <div v-if="!form.logo" class="form-group custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="customSwitchHideAvatar" v-model.trim="form.hideAvatar">
          <label class="custom-control-label" for="customSwitchHideAvatar">隐藏头像<span v-if="!form.isPro" style="color:orange" @click.prevent="$refs.proDescription.show=true">（Pro 功能<i class="fas fa-question-circle"></i>）</span></label>
        </div>
        <div class="form-group">
          <label for="">上传自定义头像<span v-if="!form.isPro" style="color:orange" @click.prevent="$refs.proDescription.show=true">（Pro 功能<i class="fas fa-question-circle"></i>）</span></label>
          <div class="">
            <div v-if="form.logo">
              <div class="card-tools float-right">
                <button type="button" class="btn btn-box-tool" @click="deleteLogo()" title="删除"><i class="fas fa-trash"></i></button>
              </div>
              <a>
                <div style="height:100px;background-repeat:no-repeat;background-size:contain;background-color:#ccc;" :style="{'background-image':'url(' + form.logo.url + ')'}"></div>
              </a>
            </div>
            <div v-else>
              <template v-if="urls">
                <UploadSingleBox :action="urls.tourLogo({tourId:$route.params.tourId})"
                                 :multiple="true"
                                 :maxSize="1"
                                 :maxWidth="6000"
                                 :maxHeight="6000"
                                 :compress="true"
                                 :isPano="false"
                                 :allowExtensions="/(.jpg|.jpeg|.png|.gif)$/i"
                                 :partSizeMB="10"
                                 :surlheight="100"
                                 :surlwidth="200"
                                 :maxUpper="3"
                                 :uploadFinished="logoUploadFinished">
                  <template v-slot:default="{file,index,deleteitem}">
                    <div class="card-tools mr-0">
                      {{file.name}}
                      <button type="button" class="btn btn-box-tool" @click="deleteitem(index)"><i class="fa fa-times"></i></button>
                    </div>
                    <a :href="file.surl" target="_blank">
                      <img :src="file.surl" />
                    </a>
                    <div class="progress">
                      <div class="progress-bar" :style="{width:file.percent+'%'}"></div>
                    </div>
                  </template>
                </UploadSingleBox>
              </template>
            </div>
          </div>
        </div>-->
        <!--<div class="form-group">
          <label for="panoTitle">公共标签</label>
          <CheckBox checkprop="name"
                    :checkList="publicTags"
                    :selected="form.tags"
                    :onChange="tagsCheckOnChange">
          </CheckBox>
        </div>
        <div class="form-group">
          <label for="panoTitle">私有标签</label>
          <CheckBox checkprop="name"
                    :checkList="privateTags"
                    :selected="form.tags"
                    :onChange="tagsCheckOnChange">
          </CheckBox>
        </div>-->
        <!--<div class="form-group">
          <button class="btn btn-outline-primary" @click="$refs.selectMapPoint.showModal()">选择地点</button>
        </div>-->
        <!--<div v-if="form.gpsLng!=0||form.gpsLat!=0">
          <MapView :mark="{lat: form.gpsLat||0,lng:form.gpsLng||0}"></MapView>
        </div>-->
        <!--<div v-if="!form.guid" class="form-group">
          <label>分类</label>
          <select class="form-control" v-model="form.categoryGuid">
            <option v-for="i in categorys" :value="i.id">{{i.text}}</option>
          </select>
        </div>-->
        <!--<div class="form-group custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="customSwitchClosed" v-model.trim="form.closed">
          <label class="custom-control-label" for="customSwitchClosed">隐藏漫游</label>
        </div>-->
        <div class="text-right">
          <button type="button" class="btn btn-primary btn-sm" @click="ok()">确定</button>
        </div>
        <MapSelectedPointModal ref="selectMapPoint" :markerPoint="{lat: form.gpsLat,lng:form.gpsLng}" @submit="changeMarker">
        </MapSelectedPointModal>
      </div>
    </component>
    <template v-if="urls">
      <FileLib ref="BaseFileLib" :libUrl="urls.fileLib()" :panosUploadUrl="urls.panosUploadUrl()" :filesUploadUrl="urls.filesUploadUrl()" :tagsUrl="urls.privateTags()" :fileUrl="urls.fileUrl()" :panoUrl="urls.panoUrl()" :params="params" @submit="BaseFileLibSelected"></FileLib>
      <AudioLib ref="AudioLib" :libUrl="urls.audioLib()" :params="params" :onSelected="AudioLibSelected"></AudioLib>
    </template>
  </div>
</template>
<script>
  import PellEditor from '../../PellEditor'
  import UploadSingleBox from '../../UploadSingleBox'
  import CheckBox from '../../CheckBox'
  import MapSelectedPointModal from '../../Map/MapSelectedPointModal'
  import MapView from '../../Map/MapView'
  import FileLib from '../../FileLib'
  import AudioLib from '../../AudioLib'

  export default {
    components: {
      PellEditor,
      UploadSingleBox,
      CheckBox,
      MapSelectedPointModal,
      MapView,
      FileLib,
      AudioLib,
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        modelShow: null,
        modelHide: null,
        publicTags: [],
        privateTags: [],
        categorys: [],
        blendModes: [
          { name: '无', blend: null },
          { name: '淡入淡出', blend: 'BLEND(1.0, easeInCubic)' },
          { name: '缩放', blend: 'ZOOMBLEND(2.0, 2.0, easeInOutSine)' },
          { name: '黑出', blend: 'COLORBLEND(2.0, 0x000000, easeOutSine)' },
          { name: '闪光', blend: 'LIGHTBLEND(1.0, 0xFFFFFF, 2.0, linear)' },
          { name: '从右到左', blend: 'SLIDEBLEND(1.0, 0.0, 0.2, linear)' },
          { name: '从上到下', blend: 'SLIDEBLEND(1.0, 90.0, 0.01, linear)' },
          { name: '斜角', blend: 'SLIDEBLEND(1.0, 135.0, 0.4, linear)' },
          { name: '圆形打开', blend: 'OPENBLEND(1.0, 0.0, 0.2, 0.0, linear)' },
          { name: '垂直打开', blend: 'OPENBLEND(0.7, 1.0, 0.1, 0.0, linear)' },
          { name: '水平打开', blend: 'OPENBLEND(1.0, -1.0, 0.3, 0.0, linear)' },
          { name: '椭圆缩放', blend: 'OPENBLEND(1.0, -0.5, 0.3, 0.8, linear)' },
        ],
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {},
      getComonent: {},
    },
    computed: {
      urls() {
        return this.publicData.urls
      },
      params() {
        return this.publicData.params
      },
      form() {
        return this.publicData.form || {}
      },
      $v() {
        return this.publicData.$v
      },
    },
    created() {
    },
    mounted() {
      this.loadTags()
      this.loadCategorys()
      this.$emit('ready', {
        vm: this,
        events: {
          proTips: {
            target: 'proTips',
            name: 'show'
          },
          changeWeather: {
            target: 'Data',
            name: 'formChage',
            arg: 'weather',
          },
          addItem: {
            target: 'SenceSettingModel',
            name: 'addItemStart',
            arg: { index: 0 }
          }
        },
        funcs: {
          show: this.show,
        }
      })
    },
    destroyed() {
    },
    methods: {
      ok() {
        if (!(this.form.items && this.form.items.length > 0)) {
          setTimeout(() => {
            this.$emit('addItem')
          }, 100)
        }
        this.modelHide()
      },
      modelReady({ funcs }) {
        this.modelShow = funcs.show
        this.modelHide = funcs.hide
      },
      show() {
        if (this.modelShow) {
          this.modelShow()
        } else {
          alert('模态框还没有准备好')
        }
      },
      onhide() {
        this.$emit('next')
      },
      loadTags() {
        if (!this.urls) {
          setTimeout(this.loadTags, 300)
          return
        }
        var tagtype = '全景';
        this.$axios.get(this.urls.publicTags(), { params: { type: tagtype } })
          .then((response) => {
            this.publicTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
        this.$axios.get(this.urls.privateTags(), { params: { type: tagtype, ...this.params } })
          .then((response) => {
            this.privateTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      loadCategorys() {
        if (!this.urls) {
          setTimeout(this.loadCategorys, 300)
          return
        }
        this.$axios.get(this.urls.categorys({ module: 'Tour' }), { params: {} })
          .then((response) => {
            this.categorys = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      logoUploadFinished(file) {
        console.log(file)
        this.form.logo = file.logo;
      },
      showBaseFileLibDialog() {
        this.$refs.BaseFileLib.showDialog();
      },
      BaseFileLibSelected(select) {
        this.form.introImage = select;
        this.$forceUpdate()
      },
      showAudioLibDialog() {
        this.$refs.AudioLib.showDialog();
      },
      AudioLibSelected(select) {
        this.form.audio = select;
      },

      deleteLogo() {
        this.$axios.delete(this.urls.tourLogo({ tourId: this.$route.params.tourId })).then(() => {
          this.form.logo = null
        })
      },
      changeMarker(p) {
        console.log('changeMarker', p)
        this.form.gpsLat = p.lat
        this.form.gpsLng = p.lng
      },
      tagsCheckOnChange(list) {
        console.log(list)
        this.form.tags = [...list]
      },
    },
  }
</script>
<style scoped>
  .introText {
    color: white;
    text-align: justify;
  }
</style>
